import { styled } from "#style/jsx";

const Container = styled("div", {
  base: {
    display: "flex",
    flexDir: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "2",
    w: "full",
  },
});

const Label = styled("label", {
  base: {
    display: "inline-flex",
    flexDir: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "2",
    textStyle: "xxsmallImportant",
    w: "full",
  },
});

const AccessibilityLabel = styled("span", {
  base: {
    display: "block",
    width: 0,
    height: 0,
    overflow: "hidden",
  },
});

const Tag = styled("span", {
  base: {
    display: "inline-block",
    textStyle: "xxsmall",
    backgroundColor: "yellow",
    px: "1",
    borderRadius: "sm",
  },
});

export enum Tags {
  Experimental = "experimental",
  New = "new",
}

export const Field = {
  Container,
  Label,
  Tag,
  AccessibilityLabel,
};
